// Custom.scss
// Option B: Include parts of Bootstrap

$primary: #9551BD;
$secondary: #fd7e14;
$success: #44995A;
$info: #56BFC9;
$warning: #ffc107;
$danger: #dc3545;
$light: #C5C7C9;
$light2: #F7F7F7;
$light3: #F4F4F4;
$light4: #E6E6E6;
$white: #ffffff;
$dark: #6D7881;
$dark2: #525C64;
$dark3: #21262C;
$primary-light: #CB72FF;
$primary-light2: #D151E2;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../bootstrap/scss/functions";

// 2. Include any default variable overrides here

$spacer: 1.6rem;
$spacers: (
  0: 0,
  1: 3rem,
  2: 4rem,
  3: 5.6rem,
  4: 8.6rem,
  5: 10rem,
  6: 12rem,
);

$font-family-sans-serif: 'Poppins', sans-serif;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$font-size-base: 1.5rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.8;
$small-font-size: 80%;


$h1-font-size: 4rem;
$h2-font-size: 3rem;
$h3-font-size: 2rem;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: 3.5rem;
$headings-font-weight: 700;
$headings-line-height: 1.4;
$headings-color: $dark3;




$body-bg: $white;
$body-color: $dark;


$paragraph-margin-bottom: 1rem !default;



$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1330px,
  xxxl: 1560px);


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px,
  xxxl: 1530px);

$grid-columns: 12;
$grid-gutter-width: 3rem;
$grid-row-columns: 6 !default;


$link-color: $primary !default;
$link-decoration: underline !default;
$link-hover-color: $primary;
$link-hover-decoration: null !default;


$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-border-width: 0;
$btn-font-weight: 500;
/* Btn disable box shadows */
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

/* border radius*/
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;


$enable-negative-margins: true;

// 3. Include remainder of required Bootstrap stylesheets
@import "../bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../bootstrap/scss/maps";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../bootstrap/scss/utilities";
@import "../bootstrap/scss/reboot";
@import "../bootstrap/scss/type";
@import "../bootstrap/scss/images";
@import "../bootstrap/scss/containers";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/helpers";
@import "../bootstrap/scss/tables";
@import "../bootstrap/scss/forms";
@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/transitions";
@import "../bootstrap/scss/dropdown";
@import "../bootstrap/scss/button-group";
@import "../bootstrap/scss/nav";
@import "../bootstrap/scss/navbar";
@import "../bootstrap/scss/card";
@import "../bootstrap/scss/accordion";
@import "../bootstrap/scss/breadcrumb";
@import "../bootstrap/scss/pagination";
@import "../bootstrap/scss/badge";
@import "../bootstrap/scss/alert";
@import "../bootstrap/scss/progress";
@import "../bootstrap/scss/list-group";
@import "../bootstrap/scss/close";
@import "../bootstrap/scss/toasts";
@import "../bootstrap/scss/modal";
@import "../bootstrap/scss/tooltip";
@import "../bootstrap/scss/popover";
@import "../bootstrap/scss/carousel";
@import "../bootstrap/scss/spinners";
@import "../bootstrap/scss/offcanvas";
@import "../bootstrap/scss/placeholders";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../bootstrap/scss/utilities/api";

// 8. Add additional custom code here